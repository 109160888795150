<section class="purpose-content-block" #el [ngClass]="'purpose-content-block--' + teasersLength">
  <main class="block-content purpose-content-block__main spacer-bottom-l" #main>
    <div class="purpose-content-block__main__inner">
      <h1 class="h3 block-content__title purpose-content-block__title" *ngIf="block.title">{{ block.title }}</h1>
      <div
        class="block-content__text purpose-content-block__text"
        *ngIf="block.text"
        [innerHTML]="block.text | stripEmbed : (cookiesAccepted$ | async) | safe"
        fstrDynamicContent
      ></div>

      <footer class="purpose-content-block__main__footer" *ngIf="block.quote || block.buttonUrl">
        <div class="block-content__quote purpose-content-block__quote" *ngIf="block.quote">
          <blockquote [innerHTML]="block.quote | safe"></blockquote>
          <figcaption class="" *ngIf="block.author">{{ block.author }}</figcaption>
        </div>

        <ng-container *ngIf="block.buttonUrl && block.buttonText">
          <a
            *ngIf="block.routerLink"
            class="button color-black button--border button--small"
            [routerLink]="block.routerLink"
            [queryParams]="block?.queryParams"
          >
            <span>{{ block.buttonText }}</span>
          </a>

          <a
            *ngIf="!block.routerLink"
            class="button color-black button--border button--small"
            [href]="block.buttonUrl"
            [attr.data-text]="block.buttonText"
          >
            <span>{{ block.buttonText }}</span>
          </a>
        </ng-container>
      </footer>
    </div>
  </main>

  <ul class="purpose-content-block__teasers" [ngClass]="'images--' + teasersLength">
    <li
      class="purpose-content-block__teasers__item"
      [ngClass]="'index--' + i"
      *ngFor="let item of block.teasers | slice : 0 : 3; let i = index"
    >
      <app-purpose-teaser [data]="item"></app-purpose-teaser>
    </li>
  </ul>
</section>
