<section class="row purpose-content-block-v2" [class.has--random-exceptions]="indexOfType === 2" [ngClass]="'index--' + indexOfType">
  <main class="offset-sm-2 col-sm-12 purpose-content-block-v2__main">
    <h1 class="h3 block-content__title" *ngIf="block.title" [class.padding-inset-image]="block.secondImageUrl && !block.secondImageUrl">
      {{ block.title }}
    </h1>

    <div
      class="text-max-width"
      *ngIf="block.text"
      [innerHTML]="block.text | stripEmbed : (cookiesAccepted$ | async) | safe"
      fstrDynamicContent
      [class.padding-inset-image]="block.secondImageUrl"
    ></div>
  </main>

  <div
    class="offset-sm-2 purpose-content-block-v2__image"
    *ngIf="block.firstImageUrl"
    [ngClass]="(imagesArePortrait[0] ? 'col-20 offset-2' : 'col-24') + ' ' + (indexOfType % 3 === 0 ? 'col-sm-7' : 'col-sm-8')"
  >
    <img #img1 (load)="loadedImg(0, img1)" [alt]="block.firstImageCaption || block.title" [src]="block.firstImageUrl | resize : 1900" />
  </div>

  <div
    class="col-sm-{{ indexOfType === 2 ? '10' : '14' }} inset-image purpose-content-block-v2__image-secondary"
    *ngIf="block.secondImageUrl"
  >
    <img #img2 (load)="loadedImg(1, img2)" [alt]="block.secondImageCaption || block.title" [src]="block.secondImageUrl | resize : 1900" />
  </div>
</section>

<!--<section class="purpose-content-block" #el [ngClass]="'purpose-content-block--' + teasersLength">
  <main class="block-content purpose-content-block__main spacer-bottom-l" #main>
    <div class="purpose-content-block__main__inner">
      <h1 class="h3 block-content__title purpose-content-block__title" *ngIf="block.title">{{ block.title }}</h1>
      <div
        class="block-content__text purpose-content-block__text"
        *ngIf="block.text"
        [innerHTML]="block.text | stripEmbed : (cookiesAccepted$ | async) | safe"
        fstrDynamicContent
      ></div>

      <footer class="purpose-content-block__main__footer" *ngIf="block.quote || block.buttonUrl">
        <div class="block-content__quote purpose-content-block__quote" *ngIf="block.quote">
          <blockquote [innerHTML]="block.quote | safe"></blockquote>
          <figcaption class="" *ngIf="block.author">{{ block.author }}</figcaption>
        </div>

        <ng-container *ngIf="block.buttonUrl && block.buttonText">
          <a
            *ngIf="block.routerLink"
            class="button color-black button--border button--small"
            [routerLink]="block.routerLink"
            [queryParams]="block?.queryParams"
          >
            <span>{{ block.buttonText }}</span>
          </a>

          <a
            *ngIf="!block.routerLink"
            class="button color-black button--border button--small"
            [href]="block.buttonUrl"
            [attr.data-text]="block.buttonText"
          >
            <span>{{ block.buttonText }}</span>
          </a>
        </ng-container>
      </footer>
    </div>
  </main>
</section>-->
