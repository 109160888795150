import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlock } from '../../models/content-block.model';

@Component({
  selector: 'app-text-wide-content-block',
  templateUrl: './text-wide-content-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextWideContentBlockComponent {
  @Input() block: ContentBlock;
}
