import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Inject,
  PLATFORM_ID,
  AfterViewInit,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { Observable } from 'rxjs';
import { ContentState } from '../../../content/store';
import { ContentBlock } from '../../models/content-block.model';

@Component({
  selector: 'app-purpose-content-block-v2',
  templateUrl: './purpose-content-block-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurposeContentBlockComponentV2 {
  @Input() block: ContentBlock;
  cookiesAccepted$: Observable<CookiePreferences>;
  @Input() indexOfType: number;

  imagesArePortrait = [false, false];

  loadedImg(index: number, img) {
    this.imagesArePortrait[index] = img.naturalHeight > img.naturalWidth;
  }
}
