import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { Observable } from 'rxjs';
import { ContentState } from '../../../content/store';
import { ContentBlock } from '../../models/content-block.model';

@Component({
  selector: 'app-project-content-block',
  templateUrl: './project-content-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectContentBlockComponent implements OnInit, AfterViewInit {
  @Input() block: ContentBlock;
  @Input() blocks: ContentBlock[];
  @Output() showImage = new EventEmitter<any>();
  inview = false;
  playerVars = { autoplay: true, controls: false, muted: true, loop: false };
  pastBlockOneImage = false;
  cookiesAccepted$: Observable<CookiePreferences>;

  @ViewChild('firstImage') firstImage: ElementRef<HTMLElement>;

  constructor(private cd: ChangeDetectorRef, private store: Store<ContentState>) {}

  ngOnInit(): void {
    this.cookiesAccepted$ = this.store.select(getCookiePreferences);
  }

  ngAfterViewInit(): void {
    // check if past image block has 1 image
    const index = this.blocks.findIndex(item => item.id === this.block.id);

    // this.block.firstImageUrl
    const check = this.blocks[index - 1];
    if (check) {
      this.pastBlockOneImage =
        (check.firstImageUrl && !check.secondImageUrl) || (!check.firstImageUrl && check.secondImageUrl) ? true : false;
      this.cd.detectChanges();
    }
  }
}
